import React from "react";
import { Fragment } from 'react'
import { Disclosure, Menu, Transition } from '@headlessui/react'
import { Bars3Icon, BellIcon, XMarkIcon } from '@heroicons/react/24/outline'

function About() {
  return (
    <div >
     <p className="text-2xl font-bold">about</p>
    </div>
  );
}


export default About;