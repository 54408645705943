import React from "react";
import "./App.css";
import RoutesProject from "./routs/RoutsProject";
import AOS from "aos";
import "aos/dist/aos.css";

function App() {
  React.useEffect(() => {
    AOS.init({
      offset: 100,
      duration: 800,
      easing: "ease-in-sine",
      delay: 100,
    });
    AOS.refresh();
  }, []);
  return (
    <>
      <RoutesProject />
    </>
  );
}

export default App;
