import React from "react";
import { Routes, Route } from "react-router-dom";
import Home from "../pages/Home/Home";
import PrivacyPolicy from "../components/PrivacyPolicy/PrivacyPolicy";
import About from "../components/About/about";
import NavigationMenu from "../components/Navbar/NavigationMenu";
function RoutesProject() {
    return (
      <>
        {/* <NavigationMenu /> */}
        <Routes>
          <Route index element={<Home />} />
          <Route path="/privacy-policy" element={<PrivacyPolicy />} />
          <Route path="/about" element={<About />} />
        </Routes>
      </>
    );
  }
  
  export default RoutesProject;