import React from "react";
import "../../styles/LandingPage.css";
import Img1 from "../../assets/images/vfdscreen.png";
import Img2 from "../../assets/images/vfdscreen2.jpg";
import Img5 from "../../assets/images/vfdscreen3.jpg";
import Img6 from "../../assets/images/vfdscreen4.jpg";
import Img7 from "../../assets/images/vfdscreen5.jpg";
import Img8 from "../../assets/images/vfdscreen6.jpg";
import Img3 from "../../assets/images/sibapp.png";
import Img4 from "../../assets/images/bazaar.png";
import { GrFormCheckmark } from "react-icons/gr";
import Slider from "react-slick";

const ImageList = [
  {
    id: 1,
    img: Img1,
  },
  {
    id: 2,
    img: Img2,
  },
  {
    id: 3,
    img: Img5,
  },
  {
    id: 4,
    img: Img6,
  },
  {
    id: 5,
    img: Img7,
  },
  {
    id: 6,
    img: Img8,
  },
];

function LandingPage() {
  var settings = {
    dots: false,
    arrows: false,
    infinite: true,
    speed: 800,
    slidesToScroll: 1,
    autoplay: true,
    autoplaySpeed: 4000,
    cssEase: "ease-in-out",
    pauseOnHover: false,
    pauseOnFocus: true,
  };

  return (
    <div className="h-screen flex justify-center py-12 sm:py-0">
      <div className="container ">
        <div className="grid grid-cols-1 sm:grid-cols-2 sm:gap-36 gap-16">
          {/* image section */}

          <div
            data-aos="zoom-in"
            className=" p-10 gap-6 h-screen w-full hidden sm:flex  "
          >
            <img
              src={Img5}
              alt=""
              className=" place-self-end rounded-md max-w-[400px] h-[250px] sm:max-w-[200px] sm:h-3/6 mx-auto drop-shadow-[-10px_10px_12px_rgba(0,0,0,1)] object-scale-down"
            />
            <img
              src={Img1}
              alt=""
              className="rounded-md max-w-[400px] h-[250px] sm:max-w-[300px] sm:h-4/6 mx-auto drop-shadow-[-10px_10px_12px_rgba(0,0,0,1)] object-scale-down"
            />
            <img
              src={Img8}
              alt=""
              className=" place-self-end rounded-md max-w-[400px] h-[250px] sm:max-w-[200px] sm:h-3/6 mx-auto drop-shadow-[-10px_10px_12px_rgba(0,0,0,1)] object-scale-down"
            />
          </div>
        
          {/* text details section */}
          <div className=" justify-center flex flex-col gap-16 sm:pt-0 font-face-gm h-full  items-center sm:items-start">
            <div>
              <h1
                data-aos="fade-up"
                className="sm:text-7xl font-bold text-green2 text-5xl"
              >
                VFD Library
              </h1>
            </div>
            <div className="flex flex-col justify-center gap-6 sm:pt-0 font-face-DMSans">
              <h1
                data-aos="fade-up"
                className="text-3xl sm:text-2xl font-face-gm"
              >
                Download Applicatin
              </h1>
              <p
                data-aos="fade-up"
                className="text-sm text-orange tracking-wide leading-5"
              >
                Features
              </p>
              <div className="flex flex-col gap-4 text-xs">
                <div data-aos="fade-up" className="flex items-center gap-4">
                  <GrFormCheckmark className="text-4xl h-5 w-5 shadow-sm p-1 rounded-full bg-orange text-white" />
                  <p className="text-gray-800">Quality Products</p>
                </div>
                <div data-aos="fade-up" className="flex items-center gap-4">
                  <GrFormCheckmark className="text-4xl h-5 w-5 shadow-sm p-1 rounded-full bg-orange dark:bg-orange-400 text-white" />
                  <p className="text-gray-800">Fast Delivery</p>
                </div>
                <div data-aos="fade-up" className="flex items-center gap-4">
                  <GrFormCheckmark className="text-4xl h-5 w-5 shadow-sm p-1 rounded-full bg-orange dark:bg-green-400 text-white" />
                  <p className="text-gray-800">Easy Payment method</p>
                </div>
                <div data-aos="fade-up" className="flex items-center gap-4">
                  <GrFormCheckmark className="text-4xl h-5 w-5 shadow-sm p-1 rounded-full bg-orange dark:bg-yellow-400 text-white" />
                  <p className="text-gray-800">Get Offers</p>
                </div>
              </div>
              <div className="flex space-x-4 ">
                <a data-aos="fade-up" href="#">
                  <div  className="flex justify-center">
                    <div>
                      <div className="flex w-36 h-12 sm:w-48 sm:h-14 bg-white text-gray-800 border-2 border-green4 rounded-xl items-center justify-center cursor-pointer hover:bg-green4 transition ease-in-out ">
                        <div className="mr-3">
                          <img className="h-5 w-5 sm:h-8 sm:w-8" src={Img4}/>
                        </div>
                        <div>
                          <div className="text-xs">Download on the</div>
                          <div className="font-semibold font-sans -mt-1 text-xl sm:text-2xl">
                          Bazaar
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </a>
                <a data-aos="fade-up" href="#">
                  <div  className="flex justify-center">
                    <div>
                      <div className="flex w-36 h-12 sm:w-48 sm:h-14 bg-green4 text-white border-2 border-green4 rounded-xl items-center justify-center cursor-pointer hover:bg-white hover:text-gray-800 transition ease-in-out">
                        <div className="mr-3">
                        <img className="h-7 w-7 sm:h-10 sm:w-10" src={Img3}/>
                        </div>
                        <div>
                          <div className="text-xs">Download on the</div>
                          <div className="font-semibold font-sans -mt-1 text-xl sm:text-2xl">
                            Sibapp
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </a>
              </div>
            </div>
          </div>
          <div className="sm:hidden">
            <Slider {...settings}>
              {ImageList.map((data) => (
                <div key={data.id}>
                  <div data-aos="zoom-in">
                    <img
                      src={data.img}
                      alt=""
                      className="rounded-md max-w-[400px] h-[250px] sm:max-w-[700px] sm:h-[550px] mx-auto drop-shadow-[-10px_10px_12px_rgba(0,0,0,1)] object-scale-down"
                    />
                  </div>
                </div>
              ))}
            </Slider>
          </div>
        </div>
      </div>
    </div>
  );
}

export default LandingPage;
