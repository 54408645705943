import React from "react";

function PrivacyPolicy() {
  return (
    <div >
     <p className="text-2xl font-bold">privacypolicy</p>
    </div>
  );
}


export default PrivacyPolicy;