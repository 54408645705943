import React from "react";
import LandingPage from "../../components/LandingPage/LandingPage";
function Home() {
    return (
      <>
        <LandingPage />
      </>
    );
  }
  
  export default Home;